import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import { dateFormat } from "highcharts";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { xDateFormat } from "../util/date";
import { TooltipFormatterParams } from "../util/tooltip";
import { formatQuarterDate, weekStartAndEndDates } from "common/util/date";
import { normalizeDate } from "common/util/utcDateFromString";

import ChartTooltipPoint from "./ChartTooltipPoint";

export type ChartTooltipContentProps = TooltipFormatterParams;

const ChartTooltipContent: React.FC<ChartTooltipContentProps> = ({
  points = [],
  title: _title,
  subtitle,
  statCaption,
  xAxisEnhancements = {},
  granularity,
  confidenceIntervals,
  customDateString,
  chartType,
  ...props
}) => {
  const {
    xCategories,
    categoryName = "",
    overrideXDateFormat,
    overrideXDateFormatter
  } = xAxisEnhancements;
  const point = points[0];
  const title = useMemo(() => {
    if (!isEmpty(_title) && !isNil(_title)) return _title;
    if (points.length === 1) return points[0]?.name;
    return "";
  }, [_title, points]);
  if (!point) return null;
  let formattedDate = "";
  let category: null | string = null;
  const categories = xCategories;
  const categoriesReadableDict = xAxisEnhancements?.xCategoriesReadableDict;
  if (category || categories?.length) {
    category = point?.category ?? categories?.[point?.x ?? 0] ?? "";
    category && categoriesReadableDict && (category = categoriesReadableDict[category] ?? "");
  } else {
    if (overrideXDateFormatter && point?.x) {
      formattedDate = overrideXDateFormatter({
        date: point.x,
        granularity: granularity ?? undefined
      });
    } else if (granularity === MhcTimeSeriesGranularityEnum.Week) {
      formattedDate = weekStartAndEndDates({
        end: normalizeDate(new Date(point.x as number)),
        formatAsString: true
      }) as string;
    } else if (granularity === MhcTimeSeriesGranularityEnum.Quarter) {
      formattedDate = formatQuarterDate(normalizeDate(new Date(point.x as number)), {
        useFullMonthName: true
      });
    } else {
      formattedDate = dateFormat(
        overrideXDateFormat ?? xDateFormat(granularity ?? undefined),
        point?.x ?? 0
      );
    }
  }

  return (
    <Stack gap={0.75}>
      <Stack gap={0.5}>
        {title && (
          <Typography
            variant="body3"
            fontWeight={600}
            lineHeight={1}
            minWidth={title.length > 50 ? 300 : undefined}
            display="block"
          >
            {title}
          </Typography>
        )}
      </Stack>
      {statCaption && (
        <Typography variant="body3" color="#666666" lineHeight={1} whiteSpace="nowrap">
          {statCaption}
        </Typography>
      )}
      {isNil(statCaption) && subtitle && (
        <Typography variant="caption" color="#666666" lineHeight={1} whiteSpace="nowrap">
          {subtitle}
        </Typography>
      )}
      {formattedDate && !customDateString && (
        <Typography variant="body4" lineHeight={1}>
          {formattedDate}
        </Typography>
      )}
      {category && (
        <Typography variant={"body3"} lineHeight={1}>
          {categoryName && `${categoryName} `}
          {category}
        </Typography>
      )}
      {customDateString && (
        <Typography variant="body4" lineHeight={1} mb={0.25}>
          {customDateString}
        </Typography>
      )}
      {points.length && (
        <Stack gap={0.5}>
          {sortBy(points, (point) => -point.y).map((point, i) => (
            <ChartTooltipPoint
              point={point}
              key={`chart-point-${i}-${point.point.index as number}`}
              single={false}
              confidenceIntervals={confidenceIntervals}
              xAxisEnhancements={xAxisEnhancements}
              chartType={chartType}
              {...props}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default ChartTooltipContent;
