"use client";

import pick from "lodash/pick";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import { Tooltip, TooltipFormatterContextObject, TooltipOptions } from "highcharts";

import { MhcConfidenceInterval, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { formatDateByGranularity, formatValueByUnit } from "common/util/formatHelpers";

import ChartTooltipContent from "../ChartTooltip/ChartTooltipContent";
import { LineChartXAxisEnhancement } from "../LineChart";
import { StackedColumnChartProps } from "./stackedColumnChartTypes";

export interface ConvertPointToTooltipPointParams extends TooltipFormatterContextObject {
  x?: number;
  name?: string;
  index?: number;
  category?: string;
}

export const convertPointToTooltipPoint = ({
  x,
  y,
  color,
  colorIndex,
  series,
  point,
  percentage,
  ...props
}: ConvertPointToTooltipPointParams): TooltipPoint => {
  return {
    x: x,
    category: typeof x === "string" ? x : props.category,
    y: y ?? point?.y ?? 0,
    color: typeof color === "string" ? color : undefined,
    colorIndex,
    series: pick(series, ["name", "index"]),
    percentage,
    point: {
      ...point,
      name: point?.name ?? props.name ?? "",
      index: point?.index ?? props.index ?? 0
    },
    ...props
  };
};

export const pointsFromContext = (
  context: TooltipFormatterContextObject,
  useAllFromSeries?: boolean
): TooltipPoint[] => {
  let points = (
    context.points?.length ? context.points : [context.point]
  ) as ConvertPointToTooltipPointParams[];
  if (useAllFromSeries === true) {
    points = context.series.chart.series
      .filter((series) => series.visible === true)
      .map(
        (series) => series.data[context.point.index]
      ) as unknown as ConvertPointToTooltipPointParams[];
  }
  return points?.map(convertPointToTooltipPoint) ?? [];
};

export const defaultTooltipOptions: Partial<TooltipOptions> = {
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  borderWidth: 2,
  borderRadius: 4,
  nullFormat: "N/A",
  outside: true,
  style: {
    zIndex: 10000
  }
};

type SeriesPoint = {
  name: string;
  index: number;
  confidenceIntervalIndex?: number;
};

export type TooltipPoint = {
  x?: number;
  category?: string;
  y: number;
  percentage?: number;
  color?: string;
  colorIndex?: number;
  series: SeriesPoint;
  point: SeriesPoint & { suppressed?: boolean };
  name?: string;
};

export type TooltipFormatterOptions = {
  categoriesAreDates?: boolean;
  shared?: boolean;
  showNonPercentageValue?: boolean;
  showTotal?: boolean;
  title?: string;
};
export interface TooltipFormatterParams<
  XAxisEnhancement extends object = LineChartXAxisEnhancement
> {
  /** list of confidince interval data (upper/lower values and weightedFrequency) @see MhcConfidenceInterval  */
  confidenceIntervals?: MhcConfidenceInterval[][];
  /** Date string that overrides automatically rendered string  */
  customDateString?: string;
  /** time window of data (eg yearly, weekly, etc)  */
  granularity?: MhcTimeSeriesGranularityEnum | null;
  /** data point is a percentage */
  percent?: boolean;
  /** List of point info from Highcharts  */
  points: TooltipPoint[];
  /** number of decimal points for formatted value  */
  precision?: number;
  /** text that appears with value  */
  statCaption?: string | null;
  /** custom text appearing below title  */
  subtitle?: string;
  title?: string;
  /** unit for value formatting */
  unit?: string | null;
  /** additional options of x-axis to determine special formatting  */
  xAxisEnhancements?: XAxisEnhancement;
  /** additional options @see TooltipFormatterOptions  */
  options?: TooltipFormatterOptions;
  /** Defines the chart type */
  chartType?: "donut";
}

export const chartTooltipFormatter = <XAxisEnhancement extends object = LineChartXAxisEnhancement>(
  params: TooltipFormatterParams<XAxisEnhancement> & { xAxisEnhancements?: XAxisEnhancement }
) => {
  const { points, ...props } = params;
  const div = document.createElement("div");
  const root = createRoot(div);
  flushSync(() => {
    root.render(<ChartTooltipContent points={points} {...props} />);
  });
  return div.innerHTML;
};

type TooltipForStackedColumnParams = {
  tooltip: Tooltip;
} & Pick<
  StackedColumnChartProps,
  "precision" | "percent" | "granularity" | "timeSeries" | "valuesDescription" | "categories"
>;

/**
 * Creates a tooltip for a stacked column chart as string
 *
 * @param props - configuration object
 * @param props.tooltip - Tooltip object
 * @param props.precision - number of decimal places
 * @param props.percent - values are percentages
 * @param props.granularity - selected granularity for configuration
 * @param props.timeSeries - chart presents time series data
 * @param props.categories - categories implemented on the chart
 * @param props.valuesDescription - description of the value
 *
 * @returns string that's injected into tooltip
 *
 * @deprecated - use chartTooltipFormatter instead
 */
export const tooltipForStackedColumn = ({
  tooltip,
  precision,
  percent,
  granularity,
  timeSeries,
  valuesDescription,
  categories
}: TooltipForStackedColumnParams) => {
  const value = (() => {
    let returnString = "";
    const hoveredSeries = tooltip.chart.hoverSeries ? tooltip.chart.hoverSeries : undefined;
    if (hoveredSeries?.data.length === 0 || hoveredSeries?.data === undefined) {
      return "";
    }
    const dataPoint = (() => {
      if (timeSeries === true) {
        const filtered = hoveredSeries?.data.filter(
          (dp) => dp.category == `${tooltip.chart.hoverPoint?.x ?? 0}`
        );
        return filtered.length > 0 ? filtered[0] : undefined;
      }
      return hoveredSeries?.data[tooltip.chart.hoverPoint?.x ?? 0];
    })();
    if (dataPoint === undefined) {
      return "";
    }
    returnString = `${returnString} ${dataPoint?.name ?? ""}: ${
      formatValueByUnit({
        value: dataPoint?.y,
        unit: percent === true ? "percent" : "count",
        precision,
        isPercent: percent ?? false
      }) ?? ""
    }<br/>`;
    return returnString;
  })();
  const date =
    timeSeries === true
      ? tooltip.chart.hoverPoint?.x !== undefined
        ? new Date(tooltip.chart.hoverPoint?.x)
        : undefined
      : undefined;
  const dateString =
    date !== undefined
      ? `${formatDateByGranularity({ value: date, granularity: granularity })}`
      : undefined;
  const categoryName =
    (categories !== undefined ? categories[tooltip.chart.hoverPoint?.x ?? 0] : "") ?? "";
  const title = `<b>${valuesDescription ?? ""}</b> <br/>${dateString ?? categoryName}`;
  return (
    title +
    "<br/><br/>" +
    value +
    "<br/>" +
    "Total: " +
    formatValueByUnit({
      value: tooltip.chart.hoverPoint?.total,
      unit: percent === true ? "percent" : "count",
      precision,
      isPercent: percent ?? false
    })
  );
};
